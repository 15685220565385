
import { defineComponent, ref } from 'vue';
import ReceiptForm from './components/ReceiptForm.vue';
import ReceiptPDF from './components/ReceiptPDF.vue';

interface ReceiptData {
    items: {
        name: string;
        quantity: number;
        price: number;
    }[];
}

export default defineComponent({
    components: {
        ReceiptForm,
        ReceiptPDF,
    },
    setup() {
        const receiptData = ref<ReceiptData | null>(null);
        const receiptPdf = ref<any>(null);

        const generateReceipt = (data: ReceiptData) => {
            receiptData.value = data;
            if (receiptPdf.value) {
                receiptPdf.value.generatePDF();
            }
        };

        return { receiptData, receiptPdf, generateReceipt };
    },
});
