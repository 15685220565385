
import { defineComponent, reactive } from 'vue';
import { ReceiptData } from './types';

export default defineComponent({
    emits: ['submit'],
    setup(props, { emit }) {
        const form = reactive<ReceiptData>({
            companyName: '',
            companyAddress: '',
            companyPhone: '',
            taxId: '',
            customerName: '',
            items: [{ name: '', quantity: null, price: null, taxRate: null }],
        });

        function addItem() {
            form.items.push({ name: '', quantity: null, price: null, taxRate: null });
        }

        function removeItem(index: number) {
            if(form.items.length <= 1){
                alert('商品は最低1つは必須です。');
                return;
            }
            form.items.splice(index, 1);
        }

        function onSubmit() {
            if (form.items.length === 0 || !form.items.every(item => item.name && item.quantity && item.price && item.taxRate)) {
                alert('商品は最低1つは必須です。各商品は商品名、数量、価格、税率の全てを必須とします。');
                return;
            }

            if (!form.companyName || !form.customerName) {
                alert('受領者名、会社名は必須です。');
                return;
            }

            emit('submit', form);
        }

        return {
            form,
            addItem,
            removeItem,
            onSubmit,
        };
    },
});
