import { createApp } from 'vue'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const firebaseConfig = {
    apiKey: "AIzaSyBvz6TsIWEoJPi2aUeU7YlZmhEnjakxij8",
    authDomain: "receipts-zip.firebaseapp.com",
    projectId: "receipts-zip",
    storageBucket: "receipts-zip.appspot.com",
    messagingSenderId: "326028605461",
    appId: "1:326028605461:web:3afadfb0196604e9e3761c",
    measurementId: "G-05D1ETNHYJ"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const vuetify = createVuetify({
    components,
    directives,
})

createApp(App).use(vuetify).mount('#app')
