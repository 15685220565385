
import { defineComponent } from 'vue';
import { PDFDocument, rgb, PDFFont } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { ReceiptData, ReceiptItem } from './types';

export default defineComponent({
    props: {
        receiptData: Object as () => ReceiptData,
    },
    methods: {
        formatCurrency(value: number) {
            return value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
        },
        async generatePDF() {
            if (!this.receiptData) {
                alert("領収書データがありません");
                return;
            }

            const items:({
                taxRate: number;
                quantity: number;
                price: number;
                name: string
            } | null)[] = this.receiptData.items.map(item => {
                if (item.quantity !== null && item.price !== null && item.taxRate !== null) {
                    return {
                        name: item.name,
                        quantity: item.quantity,
                        price: item.price,
                        taxRate: item.taxRate,
                    };
                }
                return null})

            const invalidItems = items.filter(item => item === null);
            if (invalidItems.length > 0) {
                alert('商品は最低1つは必須です。各商品は商品名、数量、価格、税率の全てを必須とします。');
                return;
            }
            const validItems = items.filter(item => item !== null) as {
                taxRate: number;
                quantity: number;
                price: number;
                name: string
            }[];

            const fontUrl = `${window.location.origin}/fonts/NotoSansJP-Regular.ttf`;
            const fontBytes = await axios.get(fontUrl, {
                responseType: 'arraybuffer',
            });

            const pdfDoc = await PDFDocument.create();
            pdfDoc.registerFontkit(fontkit);
            const font: PDFFont = await pdfDoc.embedFont(new Uint8Array(fontBytes.data));
            const smallerFont: PDFFont = await pdfDoc.embedFont(new Uint8Array(fontBytes.data));

            const page = pdfDoc.addPage();
            const { width, height } = page.getSize();
            const fontSize = 20;
            const smallerFontSize = 14;

            const drawText = (text: string, x: number, y: number, size: number, font: PDFFont) => {
                page.drawText(text, {
                    x,
                    y,
                    size,
                    font,
                    color: rgb(0, 0, 0),
                });
            };

            const drawLine = (startX: number, startY: number, endX: number, endY: number) => {
                page.drawRectangle({
                    x: startX,
                    y: startY,
                    width: endX - startX,
                    height: endY - startY,
                    borderColor: rgb(0, 0, 0),
                    borderWidth: 1,
                });
            };

            const startY = height - 50;
            const itemStartY = startY - 170;
            const itemEndY = itemStartY - validItems.length * 25 - 20; // Increased line height

            const taxBreakdown = validItems.reduce((acc, item) => {
                if (!acc[item.taxRate!]) {
                    acc[item.taxRate!] = { subTotal: 0, taxAmount: 0 };
                }
                acc[item.taxRate!].subTotal += item.price! * item.quantity!;
                acc[item.taxRate!].taxAmount += item.price! * item.quantity! * (item.taxRate! / 100);
                return acc;
            }, {} as { [key: number]: { subTotal: number; taxAmount: number } });

            let totalTaxAmount = 0;
            for (const taxRate in taxBreakdown) {
                totalTaxAmount += taxBreakdown[taxRate].taxAmount;
            }
            // End of new logic for tax breakdown

            drawText('領収書', width / 2 - 20, startY, fontSize, font);
            drawText(this.receiptData.customerName + ' 様', 50, startY - 30, fontSize, font);
            drawText('取引日：' + new Date().toLocaleDateString(), width - 200, startY, smallerFontSize, smallerFont); // Moved text to the left
            drawText(this.receiptData.companyName, width - 200, startY - 30, fontSize, font); // Moved text to the left
            drawText(this.receiptData.companyAddress, width - 200, startY - 60, smallerFontSize, smallerFont); // Moved text to the left
            drawText(this.receiptData.companyPhone, width - 200, startY - 80, smallerFontSize, smallerFont); // Moved text to the left
            drawText('税務署登録番号: ' + this.receiptData.taxId, width - 200, startY - 100, smallerFontSize, smallerFont); // Moved text to the left

            drawText('商品名', 50, itemStartY, smallerFontSize, smallerFont);
            drawText('数量', 200, itemStartY, smallerFontSize, smallerFont);
            drawText('価格', 350, itemStartY, smallerFontSize, smallerFont);
            drawText('税率', 450, itemStartY, smallerFontSize, smallerFont);  // Added
            drawText('金額', 500, itemStartY, smallerFontSize, smallerFont); // Moved text to the left

            drawLine(40, itemStartY - 10, width - 40, itemStartY - 10);
            drawLine(40, itemEndY - 10, width - 40, itemEndY - 10);

            let total = 0;
            for (let i = 0; i < validItems.length; i++) {
                const item = validItems[i];
                const itemY = itemStartY - (i + 1) * 25; // Increased line height
                drawText(item.name, 50, itemY, smallerFontSize, smallerFont);
                drawText(item.quantity.toString(), 200, itemY, smallerFontSize, smallerFont);
                drawText(this.formatCurrency(item.price), 350, itemY, smallerFontSize, smallerFont);
                drawText(item.taxRate.toString() + '%', 450, itemY, smallerFontSize, smallerFont);  // Added
                const itemTotal = item.price * item.quantity;
                total += itemTotal;
                drawText(this.formatCurrency(itemTotal), 500, itemY, smallerFontSize, smallerFont); // Moved text to the left
            }

            // Draw tax breakdown
            let taxBreakdownY = itemEndY - 50; // Increased line height
            for (const taxRate in taxBreakdown) {
                drawText(`税率 ${taxRate}% 小計:`, 350, taxBreakdownY, smallerFontSize, smallerFont);
                drawText(this.formatCurrency(taxBreakdown[taxRate].subTotal), 500, taxBreakdownY, smallerFontSize, smallerFont); // Moved text to the left
                taxBreakdownY -= 25; // Increased line height
                drawText(`税率 ${taxRate}% 税額:`, 350, taxBreakdownY, smallerFontSize, smallerFont);
                drawText(this.formatCurrency(taxBreakdown[taxRate].taxAmount), 500, taxBreakdownY, smallerFontSize, smallerFont); // Moved text to the left
                taxBreakdownY -= 25; // Increased line height
            }

            drawText('合計', 350, taxBreakdownY - 40, fontSize, font);
            drawText(this.formatCurrency(total + totalTaxAmount), 500, taxBreakdownY - 40, fontSize, font); // Moved text to the left

            const pdfBytes = await pdfDoc.save();

            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            saveAs(blob, 'receipt.pdf');
        },
    },
    mounted() {
        this.generatePDF();
    },
});
